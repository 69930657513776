import type { Signer } from "@ethersproject/abstract-signer"
import type { Provider } from "@ethersproject/providers"

import type { ERC721 } from "contracts/bindings/ERC721"
import { ERC721__factory as ERC721Factory } from "contracts/bindings/factories/ERC721__factory"

export const getErc721 = (
  address: string,
  signerOrProvider: Signer | Provider,
): ERC721 => ERC721Factory.connect(address, signerOrProvider)
