type DelegateSettings = {
  buttons?: {
    label: string
    delegateAddress: string
    moreInfoUrl?: string
  }[]
  afterDelegateRegistration?: {
    message: string
  }
}

// Hardcoded list of organizations with delegate settings
const DELEGATE_ORG_SETTINGS: Record<
  string,
  Record<string, DelegateSettings>
> = {
  "https://api.tally.xyz": {
    // Arbitrum: https://www.tally.xyz/gov/arbitrum
    "2206072050315953936": {
      buttons: [
        {
          label: "Exclude from Quorum",
          delegateAddress: "0x00000000000000000000000000000000000A4B86",
          moreInfoUrl:
            "https://docs.arbitrum.foundation/dao-faqs#what-does-it-mean-for-an-arb-voter-to-exclude-their-votes-why-is-this-functionality-beneficial--",
        },
      ],
    },

    // Wormhole: https://www.tally.xyz/gov/wormhole
    "2323517483434116775": {
      afterDelegateRegistration: {
        message:
          "Publish your delegate statement onto the [Wormhole Forum](https://forum.wormhole.com/c/delegates/8)",
      },
    },

    // Wormhole: https://www.tally.xyz/gov/wormhole-private
    "2332126556945647051": {
      afterDelegateRegistration: {
        message:
          "Publish your delegate statement onto the [Wormhole Forum](https://forum.wormhole.com/c/delegates/8)",
      },
    },

    // ZKsync: https://www.tally.xyz/gov/codename -> https://www.tally.xyz/gov/zksync
    "2297436623035434412": {
      afterDelegateRegistration: {
        message:
          "You will appear as a registered delegate during the upcoming claim event. Follow [@zkgov](https://x.com/zkgov) for updates.",
      },
    },

    // ZKsync: https://www.tally.xyz/gov/zksync-private
    "2332130024519369867": {
      afterDelegateRegistration: {
        message:
          "You will appear as a registered delegate during the upcoming claim event. Follow [@zkgov](https://x.com/zkgov) for updates.",
      },
    },
  },

  "https://api.staging.tally.xyz": {
    // Arbitrum: https://www.tally.xyz/gov/arbitrum
    "2206056275983205879": {
      buttons: [
        {
          label: "Exclude from Quorum",
          delegateAddress: "0x00000000000000000000000000000000000A4B86",
          moreInfoUrl:
            "https://docs.arbitrum.foundation/dao-faqs#what-does-it-mean-for-an-arb-voter-to-exclude-their-votes-why-is-this-functionality-beneficial--",
        },
      ],
    },

    // Wormhole: https://staging.tally.xyz/gov/wormhole
    "2281399285910079451": {
      afterDelegateRegistration: {
        message: "Publish your delegate statement onto the Wormhole Forum",
      },
    },
  },
}

export const getDelegateOrgSettings = (
  organizationId: string,
): DelegateSettings | undefined => {
  return (
    DELEGATE_ORG_SETTINGS?.[process.env.NEXT_PUBLIC_TALLY_API_URL]?.[
      organizationId
    ] ?? undefined
  )
}
