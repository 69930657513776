import type { FC } from "react"
import type { StackProps } from "@chakra-ui/react"
import { Button, Stack, Icon, Text } from "@chakra-ui/react"

import { Tag } from "ui/components/icons/font-awesome/Tag"
import Link from "common/components/Link"
import { EXTERNAL_ROUTES } from "common/constants/routes"

type Props = {
  addressEligible: boolean
  displayShortMessage?: boolean
  displayLearnMore?: boolean
  freeDelegationsAvailable?: number
}

const DelegationEligibleMessage: FC<Props & StackProps> = ({
  addressEligible,
  displayShortMessage = false,
  displayLearnMore = false,
  freeDelegationsAvailable = 1,
  ...stackProps
}) => {
  return (
    <Stack align="center" direction="row" spacing={2} {...stackProps}>
      {addressEligible ? (
        <>
          <Icon as={Tag} color="green.600" />
          <Text color="green.600" fontWeight="500" textStyle="sm">
            {displayShortMessage
              ? `${freeDelegationsAvailable} available`
              : `Eligible for ${freeDelegationsAvailable} free delegation${
                  freeDelegationsAvailable > 1 ? "s" : ""
                }`}{" "}
            {displayLearnMore ? (
              <Link
                isExternal
                href={EXTERNAL_ROUTES.tally.docs.freeDelegation()}
                ml={2}
              >
                <Button color="gray.800" fontSize="sm" variant="link">
                  Learn more
                </Button>
              </Link>
            ) : null}
          </Text>
        </>
      ) : (
        <>
          <Icon as={Tag} color="gray.600" />
          <Text color="gray.600" fontWeight="500" textStyle="sm">
            Not eligible for free delegation{" "}
            {displayLearnMore ? (
              <Link
                isExternal
                href={EXTERNAL_ROUTES.tally.docs.freeDelegation()}
                ml={2}
              >
                <Button color="gray.800" fontSize="sm" variant="link">
                  Learn more
                </Button>
              </Link>
            ) : null}
          </Text>
        </>
      )}
    </Stack>
  )
}

export default DelegationEligibleMessage
