import type { Signer } from "@ethersproject/abstract-signer"
import { isAddress } from "@ethersproject/address"
import type { Provider } from "@ethersproject/providers"

import type { AaveStakedV2 } from "contracts/bindings/AaveStakedV2"
import { AaveStakedV2__factory as AaveStakedV2Factory } from "contracts/bindings/factories/AaveStakedV2__factory"

export const getAaveStaked = (
  address: string,
  signerOrProvider: Signer | Provider,
): AaveStakedV2 => AaveStakedV2Factory.connect(address, signerOrProvider)

export const useAaveStaked = (
  address: string,
  signerOrProvider?: Signer | Provider,
): AaveStakedV2 | undefined => {
  if (!isAddress(address) || !signerOrProvider) return

  return getAaveStaked(address, signerOrProvider)
}
