import type { Governor, Organization } from "query/graphql"

type Token = Pick<Governor["token"], "decimals" | "symbol">

const TOKEN_MAPPING: Record<string, Token> = {
  "eip155:1/erc20:0xB0fFa8000886e57F86dd5264b9582b2Ad87b2b91": {
    decimals: 18,
    symbol: "W",
  },
  "eip155:42161/erc20:0xB0fFa8000886e57F86dd5264b9582b2Ad87b2b91": {
    decimals: 18,
    symbol: "W",
  },
  "eip155:10/erc20:0xB0fFa8000886e57F86dd5264b9582b2Ad87b2b91": {
    decimals: 18,
    symbol: "W",
  },
  "eip155:8453/erc20:0xB0fFa8000886e57F86dd5264b9582b2Ad87b2b91": {
    decimals: 18,
    symbol: "W",
  },
  "eip155:11155111/erc20:0x4b56814a4A5b38De8406F3E04F5b39628658cD1B": {
    decimals: 18,
    symbol: "W",
  },
  "eip155:11155111/erc20:0x738141EFf659625F2eAD4feECDfCD94155C67f18": {
    decimals: 18,
    symbol: "W",
  },
  "eip155:421614/erc20:0x395D3C74232D12916ecA8952BA352b4d27818035": {
    decimals: 18,
    symbol: "W",
  },
  "eip155:84532/erc20:0x1d30E78B7C7fbbcef87ae6e97B5389b2e470CA4a": {
    decimals: 18,
    symbol: "W",
  },
  "eip155:300/erc20:0xbD96520e2A89232B18C4Ae4e01B6784EbAd335f8": {
    decimals: 18,
    symbol: "XJ",
  },
  "eip155:324/erc20:0x6d500c0eb63d84CBBb02340D89CED30cBC0fD3c5": {
    decimals: 18,
    symbol: "XJ",
  },
  "eip155:324/erc20:0x5A7d6b2F92C77FAD6CCaBd7EE0624E64907Eaf3E": {
    decimals: 18,
    symbol: "ZK",
  },
}

// TODO(@nicolas): A temporary fix to get the Token object from an Org while the BE supports this
export const getOrgToken = (
  organization: Organization,
  tokenId?: string,
): Token | undefined => {
  if (tokenId) {
    return TOKEN_MAPPING[tokenId]
  }

  const tokenIdFound = organization.tokenIds.find((tokenId: string) => {
    if (TOKEN_MAPPING[tokenId]) {
      return tokenId
    }

    return undefined
  })

  return tokenIdFound ? TOKEN_MAPPING[tokenIdFound] : undefined
}
