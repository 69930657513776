export enum UseDirectDelegationErrors {
  Signernotfound = "SIGNER_NOT_FOUND",
  Nonmatchingchain = "NON_MATCHING_CHAIN",
  Undefinedgovernance = "UNDEFINED_GOVERNANCE",
  Undefinedtokend = "UNDEFINED_TOKEN",
  Invalidtokenaddress = "INVALID_TOKEN_ADDRESS",
  Invalidgovernanceid = "INVALID_GOVERNANCE_ID",
  Invaliddelegateeaddress = "INVALID_DELEGATEE_ADDRESS",
  Relayererror = "RELAYER_ERROR",
}
