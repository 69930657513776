import { getDifferenceTimeFromNow } from "common/helpers/date"
import { GrpcErrorType } from "common/helpers/fetcher"
import {
  TransactionAttemptsDocument,
  type TransactionAttemptsQuery,
  type TransactionAttemptsQueryVariables,
  type TransactionType,
} from "query/graphql"
import { useQuery } from "query/hooks/useQuery"
import { getAssetIdParams } from "web3/helpers/assetId"
import { getChainByChainId } from "web3/helpers/findChain"

const blockNumbersToExpire = 10

export const useTransactionAttempts = ({
  address,
  transactionType,
  tokenId,
}: {
  address: string
  transactionType: TransactionType
  tokenId?: string
}) => {
  const { data: transactionAttemptsData } = useQuery<
    TransactionAttemptsQuery,
    TransactionAttemptsQueryVariables
  >(
    {
      query: TransactionAttemptsDocument,
      variables: {
        input: {
          address,
          transactionType,
          tokenId,
        },
      },
      omittedErrors: [GrpcErrorType.NotFound],
    },
    { enabled: Boolean(address) && Boolean(tokenId) },
  )

  const getBlockTime = () => {
    if (tokenId) {
      const { chainId } = getAssetIdParams(tokenId)
      const foundChain = getChainByChainId(chainId)

      if (foundChain) {
        return foundChain.blockTime
      }
    }

    // Default value of 10 seconds
    return 10
  }

  const blockTime = getBlockTime()
  const expireTransactionAfterSeconds = blockTime * blockNumbersToExpire

  // Remove all the transactionAttempts that are older than the expireTransactionAfterSeconds
  const transactionAttempts = transactionAttemptsData?.transactionAttempts
    ? transactionAttemptsData?.transactionAttempts.filter(
        (transactionAttempt) => {
          const diffTimeInSeconds = getDifferenceTimeFromNow(
            transactionAttempt.createdAt,
            "seconds",
          )

          return diffTimeInSeconds < expireTransactionAfterSeconds
        },
      )
    : []

  return {
    transactionAttempts,
  }
}
