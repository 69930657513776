import { HStack, Icon, Text } from "@chakra-ui/react"
import type { FC } from "react"

import { getChainMediumName } from "common/helpers/fetch"
import type { AssetId } from "query/graphql"
import GenericNetworkIcon from "web3/components/icons/GenericChainIcon"
import { getAssetIdParams } from "web3/helpers/assetId"
import { getChainByChainId } from "web3/helpers/findChain"
import type { ChainId } from "web3/constants/chains"
import WormholeLogo from "common/components/WormholeLogo"

type Props = {
  tokenId: AssetId
  isMultiChain?: boolean
}
export const TokenChainIdentity: FC<Props> = ({
  tokenId,
  isMultiChain = false,
}) => {
  const { chainId } = getAssetIdParams(tokenId)

  const foundChain = chainId ? getChainByChainId(chainId) : undefined

  const getNetworkIcon = () => {
    if (isMultiChain) return WormholeLogo

    return foundChain ? GenericNetworkIcon(foundChain) : undefined
  }

  const networkIcon = getNetworkIcon()

  const chainLabel = isMultiChain
    ? "Multichain"
    : getChainMediumName(chainId as unknown as ChainId)

  return (
    <HStack spacing={2}>
      {networkIcon ? <Icon as={networkIcon} h={4} w={4} /> : null}
      <Text fontSize="md" fontWeight="light">
        {chainLabel}
      </Text>
    </HStack>
  )
}
