import type { Signer } from "@ethersproject/abstract-signer"
import { isAddress } from "@ethersproject/address"
import type { Provider } from "@ethersproject/providers"

import type { AaveTokenV2 } from "contracts/bindings/AaveTokenV2"
import { AaveTokenV2__factory as AaveTokenV2Factory } from "contracts/bindings/factories/AaveTokenV2__factory"

export const getAaveToken = (
  address: string,
  signerOrProvider: Signer | Provider,
): AaveTokenV2 => AaveTokenV2Factory.connect(address, signerOrProvider)
// eslint-disable-next-line import/no-unused-modules
export const useAaveToken = (
  address: string,
  signerOrProvider?: Signer | Provider,
): AaveTokenV2 | undefined => {
  if (!isAddress(address) || !signerOrProvider) return

  return getAaveToken(address, signerOrProvider)
}
