import {
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Stack,
  Button,
  HStack,
  Link,
  ModalCloseButton,
} from "@chakra-ui/react"

import { EXTERNAL_ROUTES } from "common/constants/routes"
import { useMe } from "user/providers/MeProvider"

function PendingTransactionModal({
  isOpen,
  onClose,
}: {
  isOpen: boolean
  onClose: () => void
}) {
  const me = useMe()

  if (me?.type !== "SAFE" || !me.address) {
    return null
  }

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={() => onClose()}
    >
      <ModalOverlay backdropFilter="blur(5px)" />
      <ModalContent maxWidth={460} top={{ base: "unset", lg: "120px" }}>
        <ModalHeader
          borderBottomColor="gray.100"
          borderBottomWidth={1}
          p={4}
          textStyle="h5"
        >
          <HStack>
            <Text>Waiting for additional signatures</Text>
          </HStack>
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody px={4} py={4}>
          <Stack spacing={4}>
            <Text>
              Your transaction has been proposed to this Safe&apos;s transaction
              queue and needs additional signatures from other signers before it
              can be completed.
            </Text>
            <Stack>
              <Button
                isExternal
                as={Link}
                href={EXTERNAL_ROUTES.safe.queue(me.address)}
                variant="tertiary"
                onClick={() => onClose()}
              >
                View on safe app
              </Button>
            </Stack>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default PendingTransactionModal
