import { useEffect, useState } from "react"

import { API_ROUTES } from "common/constants/routes"
import { fetcher } from "common/helpers/fetcher"

type EligibleData = {
  sponsorshipSupported: boolean
  addressEligible: boolean
  freeDelegationsAvailable: number
}

export const useAddressEligibleFreeDelegation = ({
  address,
  organizationId,
  tokenId,
}: {
  address: `0x${string}` | undefined | string
  organizationId: string
  tokenId: string
}): EligibleData => {
  const [eligibleData, setEligibleData] = useState({
    sponsorshipSupported: false,
    addressEligible: false,
    freeDelegationsAvailable: 0,
  })

  const fetchIsEligible = async () => {
    if (!address) return
    if (!organizationId) return
    if (!tokenId) return

    fetcher
      .rest({
        endpoint: API_ROUTES.txRelayer.delegations.eligible(
          organizationId,
          tokenId,
          address,
        ),
        method: "GET",
      })
      .then((data) => {
        if (!data) {
          return
        }

        const eligibleData = data as EligibleData
        setEligibleData(eligibleData)
      })
      .catch(() => {
        setEligibleData({
          sponsorshipSupported: false,
          addressEligible: false,
          freeDelegationsAvailable: 0,
        })
      })
  }

  useEffect(() => {
    if (address && organizationId && tokenId) {
      fetchIsEligible()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId, tokenId, address])

  return eligibleData
}
