import { useContext } from "react"

import { NavegableModalContext } from "common/providers/NavegableModalProvider"
import type { Step } from "common/types/step"
import type { NavegableModalComponentProps } from "common/types/modal"

type Values = {
  NavegableModal: ({
    isActive,
    setIsActive,
  }: NavegableModalComponentProps) => JSX.Element | null
  onClose: () => void
  onOpen: () => void
  goToPrevious: () => void
  addStep: (step: Step) => void
}

export const useNavegableModal = (): Values => {
  const { actions, state } = useContext(NavegableModalContext)

  return { ...actions, ...state }
}
